$bodyBg: #131217;
$bgColorGradient1: $bodyBg;
$bgColorGradient2: #323138;
$bodyFontColor: #cdcdcd;
$borderMain: 1px solid #dedede;
$cardBg: #3a3841;
$cardBorderBg: rgba(255, 255, 255, 0.1);
$cryptoListColor: #333;
$cryptoListHeaderColor: #888;
$cryptoListColorHover: #555;
$cryptoListBorder: #ccc;
$cryptoListBgHover: #fff;
$deleteBgEnabled: rgba(255, 255, 255, 0.3);
$deleteBgHover: rgba(0, 0, 0, 0.5);
$deleteBgDisabled: rgba(255, 255, 255, 0.6);
$deleteColor: rgba(255, 255, 255, 0.8);
$deleteBorderColor: #000;
$fontColor100: rgba(255, 255, 255, 1);
$fontColor80: rgba(255, 255, 255, 0.8);
$fontColor50: rgba(255, 255, 255, 0.5);
$fontColor30: rgba(255, 255, 255, 0.3);
$headerBorder: rgba(255, 255, 255, 0.1);
$historicModalFontColor: #555;
$historicModalPrice: #222;
$link1Color: #419be0;
$link2Color: #cdcdcd;
$link2ColorHover: #efefef;
$link3Color: #444;
$link3ColorHover: #222;
$logoFill: #aaa;
$mainAppHeaderColor: #efefef;
$mainAppHeader2Color: #419be0;
$modalContainerBg: rgba(0, 0, 0, 0.75);
$modalPopupBg: #dedede;
$modalPopupTitleBar: #419be0;
$modalPopupTitle: #dedede;
$mobileViewResultsBorder: rgba(255, 255, 0, 0.1);
$purchasePriceColor: #ebeab9;
$resultsHeaderColor: rgba(255, 255, 255, 0.5);
$sellPriceColor: #ebeab9;
$saveCardsColor: #7abf9d;
$selectCryptoContainerBg: linear-gradient(
  0deg,
  rgba(35, 35, 35, 1) 0%,
  rgba(25, 25, 25, 1) 100%
);
$tableLoadingColor: rgba(0, 0, 0, 0.5);
$tooltipColor: rgba(75, 247, 69, 1);
$tooltipColor2: rgba(9, 187, 3, 0.5);
$tooltipArrowColor: #93cc91;
